<template>
  <v-navigation-drawer v-model="drawerOpen" app>
    <v-list-item class="primary lighten-1 py-0">
      <v-list-item-content class="py-0">
        <v-img
          :src="require('@/assets/agrilims-logo.svg')"
          class="my-3"
          alt="AgriLIMS Logo"
          aria-label="AgriLIMS Logo"
          contain
        />
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <template v-if="$auth.check('client')">
      <v-row no-gutters>
        <v-col
          v-if="selectedClient && selectedClient.id"
          :cols="hasMultipleClients ? 9 : 12"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-row no-gutters>
                <v-col>
                  <v-list-item-title class="pa-1 font-weight-bold">
                    Account #:
                  </v-list-item-title>
                </v-col>
                <v-col>
                  <v-list-item-title class="text-h6">{{
                    selectedClient.id
                  }}</v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3" v-if="hasMultipleClients" class="text-right">
          <v-list-item two-line>
            <v-list-item-content>
              <v-menu offset-y nudge-left="16">
                <template #activator="{ on, attrs}">
                  <v-btn icon v-bind="attrs" v-on="on" color="primary">
                    <v-icon>mdi-chevron-down-circle</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="client in clients"
                    :key="client.id"
                    selectable
                  >
                    <v-list-item-action>
                      <v-btn
                        icon
                        :disabled="
                          selectedClient && selectedClient.id === client.id
                        "
                        @click="setClient(client)"
                      >
                        <v-icon
                          v-if="
                            selectedClient && selectedClient.id === client.id
                          "
                        >
                          mdi-checkbox-marked
                        </v-icon>
                        <v-icon v-else>
                          mdi-checkbox-blank
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Account #: {{ client.id }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ client.title }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </template>

    <v-list dense nav>
      <template v-for="item in routes">
        <v-list-item
          v-if="!item.children || !item.children.length"
          :key="item.title"
          :to="item.route"
          :active-class="
            item.isMain ? 'router-link-main-active' : 'router-link-active'
          "
          :class="item.isMain ? 'router-link-main' : 'router-link'"
        >
          <v-list-item-icon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="item.title" />
        </v-list-item>

        <v-list-group v-else :key="item.title" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title" />
          </template>
          <!--          I don't think we need this, as long we enforce the container structure-->
          <!--          <v-list-item :to="item.route" inactive>-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>{{ item.icon }}</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-title v-text="item.title" />-->
          <!--          </v-list-item>-->
          <v-list-item
            v-for="child in item.children"
            :to="child.route"
            :key="child.title"
          >
            <v-list-item-icon>
              <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="child.title" />
          </v-list-item>
        </v-list-group>
      </template>
      <template v-if="this.user && this.user.roles.includes('client')">
        <!-- <v-list-item
          class="text-left router-link"
          :href="payment.href"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Payment
          </v-list-item-title>
        </v-list-item> -->
      </template>
      <v-divider v-if="this.user.roles.includes('client')"></v-divider>
      <LogoutMenuItem />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import LogoutMenuItem from "@/components/auth/LogoutMenuModal";

export default {
  name: "NavigationDrawer",
  components: { LogoutMenuItem },
  data() {
    return {
      right: null,
      payment: {
        href:
          "https://heartlandpaymentservices.net/webpayments/CumberlandValleyAnalytical/bills"
      }
    };
  },
  computed: {
    ...mapFields(["navigation.drawerOpen"]),
    ...mapFields("auth", ["user"]),
    ...mapGetters("clients", ["selectedClient", "hasMultipleClients"]),
    ...mapState("clients", { clients: "items" }),
    routes() {
      // Create menu items from routes
      return this.$router.options.routes
        .filter(this.filterMenuItems)
        .map(this.createMenuItem);
    }
  },
  methods: {
    ...mapMutations("clients", ["setClient"]),
    // Filters menu items that don't have attribute set to true
    filterMenuItems(route) {
      // @TODO: add functionality for route.meta.menu callbacks, and provide state for context
      return (
        route.meta.menu === true &&
        (route.meta.auth !== true ? this.$auth.check(route.meta.auth) : true)
      );
    },
    createMenuItem(route) {
      // Initialize menu item object
      let menuItem = {
        title: route.meta.title ? route.meta.title : route.name,
        route: { name: route.name }
      };
      menuItem.isMain =
        menuItem.title === "My Account" || menuItem.title === "Dashboard";
      // Add icon, if one was set
      if (route.meta.icon) menuItem.icon = route.meta.icon;
      // If this menu item has children, recursively run them through the menu build as well
      if (Array.isArray(route.children)) {
        menuItem.children = route.children
          .filter(this.filterMenuItems)
          .map(this.createMenuItem);
      }

      return menuItem;
    }
  }
};
</script>

<style scoped>
.v-list-group__items > .v-list-item {
  background-color: rgb(240, 237, 232);
  padding-left: 24px !important;
}
.v-list-item--active::before {
  opacity: 0;
}
.v-list-group .v-list-group__header {
}
.my-3 {
  height: 120px;
}
.router-link-main .v-list-item__title {
  color: white;
}
.router-link-main {
  background-color: rgb(76, 143, 114);
}
.router-link-main-active {
  background-color: rgb(22, 105, 65);
}
.router-link {
  background-color: rgb(242, 220, 184) !important;
}
.v-list-group {
  background-color: rgb(242, 220, 184) !important;
}
.v-list-group--active {
  background-color: rgb(244, 200, 125) !important;
}
</style>
